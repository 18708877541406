import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/Layout"

import Hero from "../components/Hero"
import Lead from "../components/Lead"
import SEO from "../components/SEO"
import Activities from "../components/Activities"
import { injectIntl } from "gatsby-plugin-intl"

const IndexPage = ({ intl }) => {
  const rawData = useStaticQuery(graphql`
    {
      nl: markdownRemark(
        frontmatter: { id: { eq: "home" }, langKey: { eq: "nl" } }
      ) {
        html
        ...SEO
        frontmatter {
          title
          hero {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 2612, maxHeight: 1104) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
      fr: markdownRemark(
        frontmatter: { id: { eq: "home" }, langKey: { eq: "fr" } }
      ) {
        html
        ...SEO
        frontmatter {
          title
          hero {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 2612, maxHeight: 1104) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  `)

  const data = rawData[intl.locale]
  const {
    frontmatter: { title, hero: heroes, ogImage },
    html,
  } = data

  return (
    <>
      <SEO title="Home" ogImage={ogImage} />

      <Layout>
        <Hero heroes={heroes} />
        <Lead html={html} title={title} />
        <Activities />
      </Layout>
    </>
  )
}

export default injectIntl(IndexPage)
