import React from "react"
import Img from "gatsby-image"

import { Link } from "gatsby-plugin-intl"

import badge from "../img/badge.svg"
import badgeFR from "../img/badgeFR.svg"
import { injectIntl } from "react-intl"

const Hero = ({ heroes, intl }) => {
  return (
    <div className="c-top">
      <div className="c-hero">
        {heroes.map(({ title, image }) => (
          <div key={title} className="c-hero__slide">
            <div className="c-hero__title">{title}</div>
            <Img fluid={image.childImageSharp.fluid} alt={title} />
          </div>
        ))}

        <div className="c-hero__badge">
          <Link to="/nr-1-in-camaliteiten">
            {intl.locale !== "fr" ? (
              <img src={badge} alt="Nr 1 van België in camaliteiten" />
            ) : (
              <img src={badgeFR} alt="N° 1 en Belgique dans les sinistres !" />
            )}
          </Link>
        </div>

        <a
          className="c-hero__scroll"
          data-scroll="data-scroll"
          href="#over-ons"
        >
          Scroll
        </a>
      </div>
    </div>
  )
}

export default injectIntl(Hero)
