import React from "react"

import { Link } from "gatsby-plugin-intl"
import { FormattedMessage } from "react-intl"

export default function Lead({ html, title }) {
  return (
    <div className="c-lead" id="over-ons">
      <div className="c-lead__inner">
        <div className="c-lead__content">
          <h2
            className="c-lead-title"
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <div className="c-body" dangerouslySetInnerHTML={{ __html: html }} />
          <div className="c-lead-action">
            <Link className="c-more-link" to="/over-ons">
              <FormattedMessage id="read_more" /> +
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
